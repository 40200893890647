import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/Layout"
import Metas from "../components/Metas"
import Pagination from "../components/Pagination"
import ArticleListItem from "../components/ListItems/ArticleListItem"
import PageHeader from "../components/PageHeader"

const ArticleList = ({ pageContext, data, location }) => {
  const { currentPage, numPages } = pageContext

  return (
    <Layout>
      <Metas title="Actualités" location={location}/>
      <PageHeader title="Actualités" />
      {pageContext.tags && (
        <section className="section page-filter py-0">
          <div className="container relative mx-auto px-4 py-12">
            <div className="absolute inset-y-0 left-1/2 lg:left-auto lg:right-0 w-screen border-t border-primary-lighter bg-primary transform -translate-x-1/2 lg:translate-x-0"></div>
            <div className="relative text-white">
              <div className="articles-themes">
                <div className="mb-4 font-important text-xl text-white">
                  Filtrer par catégorie :
                </div>
                <div className="flex flex-col flex-wrap lg:flex-row lg:items-center -mx-2">
                  <span className="px-2 lg:mb-2">
                    <span className="block lg:inline-block px-2 py-4 text-sm border-b border-white border-opacity-50 text-primary-light transition duration-300 lg:px-8 lg:border-b-0 lg:rounded-full lg:bg-primary-lighter lg:text-blue-night">
                      Tous
                    </span>
                  </span>
                  {pageContext.tags.map((tag, index) => (
                    <span key={index} className="px-2 lg:mb-2">
                      <Link
                        to={`/actualites${tag.path}`}
                        className="block lg:inline-block px-2 py-4 text-sm border-b border-white border-opacity-50 text-white transition duration-300 lg:px-8 lg:border-b-0 lg:rounded-full lg:bg-white lg:text-blue-night hover:text-primary-light lg:hover:text-blue-night lg:hover:bg-primary-lighter"
                      >
                        {tag.name}
                      </Link>
                    </span>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
      <section className="section page-content">
        <div className="container mx-auto px-4">
          <div className="columns grid grid-cols-1 md:grid-cols-2 gap-12">
            {data.articles.nodes.map((node, index) => (
              <div key={index} className={`column article`}>
                <ArticleListItem content={node} />
              </div>
            ))}
          </div>

          <Pagination
            path={location.pathname}
            currentPage={currentPage}
            numPages={numPages}
          />
        </div>
      </section>
    </Layout>
  )
}

export default ArticleList

export const query = graphql`
  query actualiteListQuery($skip: Int!, $limit: Int!) {
    articles: allNodeArticle(
      limit: $limit
      skip: $skip
      filter: { status: { eq: true } }
      sort: { fields: created, order: DESC }
    ) {
      nodes {
        id
        title
        created
        path {
          alias
        }
        body {
          value
          summary
        }
        relationships {
          image: field_image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 480
                  height: 304
                  quality: 75
                  transformOptions: { cropFocus: CENTER }
                  layout: CONSTRAINED
                )
              }
            }
          }
          tags: field_tags {
            name
            color: field_color
            path {
              alias
            }
          }
        }
      }
    }
  }
`
