import React from "react"
import PropTypes from "prop-types"
import { Link, navigate } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import Small16by10 from "../Images/Small16by10"

const ArticleListItem = ({ content }) => (
  <article
    className="article-list-item relative cursor-pointer"
    onClick={() => navigate(content.path.alias)}
  >
    <div className="list-item-content absolute left-0 xl:left-auto right-0 bottom-0 p-4 md:p-8 ml-16 xl:w-2/3 bg-white z-10">
      <div className="title h3 title-underlined mb-0">
        <Link to={content.path.alias} className="text-primary">
          {content.title}
        </Link>
      </div>
    </div>
    <div className="list-item-image relative xl:w-1/2 pb-16 xl:pb-0 z-0">
      {content.relationships.image ? (
        <>
          {content.relationships.banner && (
            <GatsbyImage
              image={
                content.relationships.banner.localFile.childImageSharp
                  .gatsbyImageData
              }
              alt={content.title}
              className="hidden md:block"
            />
          )}
          <GatsbyImage
            image={
              content.relationships.image.localFile.childImageSharp
                .gatsbyImageData
            }
            alt={content.title}
            className={`${
              content.relationships.banner ? `block md:hidden` : ``
            }`}
          />
        </>
      ) : (
        <Small16by10 alt={content.title} />
      )}
    </div>
  </article>
)

ArticleListItem.propTypes = {
  content: PropTypes.object.isRequired,
}

export default ArticleListItem
